*{
  font-family: Montserrat, sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

:root {
  --amazonOrange: #fbbf09 !important;
  --lightAmazonOrange: #ffd03f !important;
  --darkAmazonOrange: #ffc100 !important;
  --color-primary: var(--amazonOrange) !important;
  --color-primary-highlight: var(--lightAmazonOrange) !important;
  --ion-color-primary: var(--amazonOrange) !important;
  --ion-color-primary-tint: var(--lightAmazonOrange) !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-2,
.col-10,
.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66666%;
  max-width: 16.66666%;
}

.col-3 {
  flex: 0 0 24.99999%;
  max-width: 24.99999%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.666666%;
  max-width: 41.666666%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66666%;
  max-width: 66.66666%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.666666%;
  max-width: 91.666666%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66666%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 25%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
  }

  .offset-sm-2 {
    margin-left: 16.66666%;
  }

  .offset-sm-3 {
    margin-left: 24.99999%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Section__sectionFooter___1T54C {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

span.Section__sectionFooterSecondaryContent___Nj41Q {
  text-align: center;
  margin-top: 10px;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .content-login{
    flex-direction: column;
  }
 
  body .content-login .container-image,body  .content-login .container-form{
    max-width: 100%;
  }
  body .content-login .container-image {
    height: 200px;
    margin-bottom: 15px;
    border-bottom-right-radius: 14px;
    border-top-right-radius: 14px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.base-plan {
  position: relative;
  color: #ffffff;
}

.base-plan h1 {
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.base-plan h1:before {
  content: "";
  width: 50px;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .base-plan h1 {
    font-size: 35px;
  }
}

.base-plan .description {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
}

.base-plan .steps {
  text-align: center;
  -moz-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  -o-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.base-plan .steps .step {
  width: 44px;
  height: 44px;
  border: 1px solid var(--amazonOrange);
  color: var(--amazonOrange);
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  line-height: 44px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.base-plan .steps .step.active {
  background: var(--amazonOrange);
  color: #2c2c2c;
}

.base-plan .steps .separator {
  height: 40px;
  background: #a0a0a0;
  width: 1px;
  margin: 5px auto;
}

.btn,


.input {
  background: none;
  color: #fff;
}

.btn {
  background: var(--amazonOrange);
  color: #1c1c1c;
  font-weight: bold;
  cursor: pointer;
}

.btn:hover {
  background: var(--lightAmazonOrange);
}

div[data-test="authenticator-error"] a:after,
div[data-test="authenticator-error"] a:before {
  background: #1c1c1c !important;
}
[data-test=sign-in-create-account-link] {
  display: none;
}
.btn-light {
  height: auto;
  margin-top: 20px;
  padding-right: 38px;
  padding-left: 38px;
  border: 1px solid #fff;
  border-radius: 16px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 10px 37px;
  margin: 0;
  position: relative;
  cursor: pointer;
}
span.Section__sectionFooterPrimaryContent___2r9ZX {
  width: 100%;
}
[data-test=sign-in-forgot-password-link] {
  font-weight: 400!important;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;
  color: #000!important;
}
.btn-light:hover,button.Button__button___vS7Mv:hover,.btn:hover {
  background-color: #ffa000;
  color: #252527;
  border-color: #ffa000;
  transform: translate(0px, -3px);
}
a.forgotPassword {
  font-size: 13px;
  color: #ffffff;
}

a {
  cursor: pointer;
  color: var(--amazonOrange);
}

a:hover {
  text-decoration: underline;
}

button:disabled {
  position: relative;
}

button:disabled::before {
  content: "";
  color: #fff;
  font-family: sans-serif;
  animation: spin 0.7s linear infinite;
  position: absolute;
  left: 6px;
  top: 10px;
  border: 0.15em solid #a58a77;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  border-left-color: transparent;
}

.force-align-center {
  position: absolute;
  top: 150px;
  left: 50%;
  margin-left: -16px;
}

.spinner {
  border: 0.25em solid rgba(25, 25, 25, 0.8);
  border-left-color: transparent;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin 0.7s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.base-plan .title {
  font-size: 21px;
  font-weight: 600;
}

.base-plan .title span {
  color: var(--amazonOrange);
}

.current-steps {
  margin-bottom: 15px;
}

.current-steps ul li {
  position: relative;
  background: #1c1c1c;
  color: #5f5f5f;
  border: 1px solid #2f2f2f;
  border-radius: 50%;
  display: inline-block;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  margin: 0 30px 0px;
}

.current-steps ul li.active {
  background-color: #222222;
  border-color: #292929;
  color: #909090;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}

.current-steps ul li:after {
  color: #5f5f5f;
  content: "→";
  font-size: 25px;
  position: absolute;
  width: 20px;
  right: calc((30px + 10px) * (-1));
}

.current-steps ul li:first-child {
  margin-left: 0;
}

.current-steps ul li:last-child {
  margin-right: 0;
}

.current-steps ul li:last-child::after {
  display: none;
}

@media (min-width: 768px) {
  .subtitlecontainer {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
a.Anchor__a___1_Iz8 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
}
button.btn {
  background-color: #121816;
  color: #fff;
  border-radius: 0;
  border: none;
}

button.btn:hover {
  background-color: #121816;
  opacity: .8;
}
a.back-to-sign-in {
  color: #000;
  margin-bottom: 50px!important;
  display: block;
}
.SelectInput__selectInput___3efO4 #phone_line_number {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  border-radius: 0!important;
}
.form-signUp .col-sm-6.col,.form-signUp .col-sm-12.col {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 15px;
}
.form-signUp select[name="dial_code"] {
  border-radius: 0!important;
  border: none;
}

.Hint__hint___2XngB {
  margin-top: 20px;
}
a.Anchor__a___1_Iz8 {
  color: #252527;
}
.d-none {
  display: none;
}
.subtitle-login {
  color: #000;
  font-size: 14px;
  padding: 0 40px;
  font-weight: 400;
  margin: 0;
}
.btn{
  margin-bottom: 10px;
}

button.Button__button___vS7Mv,.btn {
  width:100%!important;
  height: 40px!important;
  padding: 9px 14px!important;
  -webkit-box-pack: center!important;
  -webkit-justify-content: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
  border-radius: 16px!important;
  background-color: #ffa100!important;
  -webkit-transition: -webkit-transform 500ms ease!important;
  transition: -webkit-transform 500ms ease!important;
  transition: transform 500ms ease!important;
  transition: transform 500ms ease, -webkit-transform 500ms ease!important;
  color: #333;
  font-size: 12px;
}

.d-flex.justify-content-center {
  display: flex;
  justify-content: center;
}

input {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px!important;
  background-color: #e6e6e5!important;
  color: #0e0e0e!important;
  font-size: 12px!important;
  border: none!important;
  margin-bottom: 15px!important;
  padding-left: 15px;

}

.Input__inputLabel___3VF0S {
  display: none;
}
.list-unstyled {
  padding-left: 0;
}
.text-center.logo h2 {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 100;
    margin: 0;
    position: relative;
    white-space: nowrap;
}
.text-center.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.Section__sectionHeader___2djyg,
.cardMiddle h1 {
  color: #313131!important;
  font-size: 14px!important;
  text-align: center;
  margin: 10px 0 20px;
}

.text-center.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center.logo a img {
  margin-right: 10px;
}
.Toast__toast___XXr3v {
  background-color: #ffa000!important;
  color: #fff!important;
  max-width: 180px;
  top: -70px;
  right: 0;
  max-width: 100%;
}
div[data-test="authenticator-error"] a:after, div[data-test="authenticator-error"] a:before {
  background: #fff!important;
}
.text-center.logo h2:before {
  content: "";
  width: 40px;
  height: 3px;
  position: absolute;
  background-color: #a58a77;
  bottom: -13px;
  left: 0;
  bottom: -5px;
}
#flashDiv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  background-color: #313131;
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
}

.form-signUp div[class*="Input__inputLabel"] {
  display: none;
}

.form-signUp div[class*="Form__formField"] {
  margin-bottom: 0;
}

div[class*="Form__formSection"] {
  box-shadow: initial !important;
}

.form-signUp select[name="dial_code"] {
  background: none !important;
  color: #757575 !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  outline: none !important;
  height: 42px !important;
  padding: 0 15px !important;
  border-color: #7d7d7d !important;
}

#phone_line_number {
  width: 100%;
  height: 42px;
  border: 1px solid #7d7d7d;
  margin-bottom: 30px;
  padding: 0 15px;
  outline: none;
  font-size: 15px;
  background: none;
  color: #fff;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

/* Powered by: https://github.com/tonystar/float-label-css */
/* Re-usable mixins * https://github.com/tonystar/float-label-css/blob/master/scss/_mixins.scss */
/* Default styling * https://github.com/tonystar/float-label-css/blob/master/scss/float-label.scss */
.has-float-label {
  display: block;
  position: relative;
}
.has-float-label label, .has-float-label > span {
  position: absolute;
  left: 0;
  top: -10px;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  transition: all 0.2s;
}
.has-float-label label b, .has-float-label > span b {
  font-weight: bold;
}
.has-float-label select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.has-float-label input, .has-float-label select {
  font-size: 13px;
  padding-top: 1em;
  margin-bottom: 2px;
  border: 0;
  border-radius: 0;
}
.has-float-label input::placeholder, .has-float-label select::placeholder {
  opacity: 1;
  transition: all 0.2s;
}
.has-float-label input:placeholder-shown:not(:focus)::placeholder, .has-float-label select:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus) + *, .has-float-label select:placeholder-shown:not(:focus) + * {
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.has-float-label input:focus, .has-float-label select:focus {
  outline: none;
  border-color: rgba(0, 0, 0, .5);
}
.has-float-label select {
  padding-right: 1em;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.5em bottom 0.25em;
  background-size: 8px 10px;
}
.Section__container___3YYTG{
  position: relative;
}


.border-top {
    position: unset;
}
div[authstate=signedIn] {
  display: none!important;
}
.bg_component {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0e0e0e;
  background-image: url('./assets/images/bg_login_v2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  padding: 20px 0px;
}

@media (max-width: 768px) {
  .bg_component {
    min-height: 100vh;
    height: auto;
    width: 100%;
  }
}


h4.subtitle-login.signUp {
  display: none;
}

.content-login {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 0 1px 4px 0 #828282;
  width: 800px;
  max-width: 90vw;
}

.row.form-signUp.d-flex.justify-content-center {
  display: flex;
  justify-content: center;
}

.content-login .container-image,.content-login .container-form {
  width: 100%;
  max-width: 50%;
}
.Form__formSection___1PPvW{
  min-width: auto!important;
  width:90%;
  padding: 35px 0!important;
}
.content-login .container-image{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 600px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    background-image: url("./assets/images/bg_left_v2.jpg");
    background-position: 30% 50%;
    background-size: cover;
}
body .Section__sectionBody___ihqqd {
  margin-bottom: 20px;
}

.container-image {
  position: relative;
}

.title-logo {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.title-logo h4 {
  position: relative;
  top: -45px;
  font-weight: 500;
  font-size: 14px;
}
.title-logo .logo {
  width: 100%;
  max-width: 220px;
  -webkit-filter: drop-shadow(2px 2px 2px #929292);
  filter: drop-shadow(2px 2px 2px #929292);
}

.title-logo .logo-soma {
  width: 100%;
  max-width: 100px;
  position: absolute;
  bottom: 10px;
}

.has-admin .container-image{
  display: none !important;
}
.has-admin .content-login .container-form{
  max-width: 100% !important;
  padding-top: 30px;
}
.has-admin div[class*="Form__formSection"] {
  padding: 15px !important;
  width: 100%;
}

.forgot-password {
  display: flex;
  justify-content: center;
  color: #000;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.sing-in-content .logo {
  max-width: 90%;
  margin: auto;
  display: block;
  width: 220px;
}

@media (max-width: 768px) {
  .sing-in-content .logo {
    width: 120px;
  }
}

.sing-in-content .logo-soma {
  display: block;
  margin: auto;
  max-width: 90%;
  width: 120px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .sing-in-content .logo-soma {
    margin: 30px auto;
  }
}


.sing-in-content form input:first-child {
  margin: 10px 0 30px!important;
}